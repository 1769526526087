// @flow
/**
 * This component represents an unadorned list of SelectItem (s).
 */
import React, {Component} from 'react';

import SelectItem from './select-item';

import type {
    Option,
} from './select-item';

type Props = {
    focusIndex: number,
    options: Array<Option>,
    selected: any,
    onSelectedChanged: (selected: any) => void,
    onClick: (index: number) => void,
    showOptions: (elems: number) => void,
    disabled?: boolean,
    selectId: string,
    shownElems: number,
    singleSelect?: boolean
};

class SelectList extends Component<Props> {
    handleSelectionChanged = (option: Option, checked: boolean) => {
        const {selected, onSelectedChanged, disabled, singleSelect} = this.props;

        if (disabled) {
            return;
        }

        if(!singleSelect) {
            if (checked) {
                onSelectedChanged([...selected, option.value]);
            } else {
                const index = selected.indexOf(option.value);
                const removed = [
                    ...selected.slice(0, index),
                    ...selected.slice(index + 1),
                ];
                onSelectedChanged(removed);
            }
        } else {
            onSelectedChanged(option)
        }
        
    }

    componentDidMount(): void {
        this.updateList(this.props)  
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.shownElems !== this.props.shownElems) {
            this.updateList(this.props)
        }
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<{}>, nextContext: any): boolean {
        if(JSON.stringify(nextProps.options || {}) !== JSON.stringify(this.props.options || {}) || 
            JSON.stringify(nextProps.selected || {}) !== JSON.stringify(this.props.selected || {}) ||
            nextProps.disabled !== this.props.disabled || nextProps.focusIndex !== this.props.focusIndex ||
            nextProps.shownElems !== this.props.shownElems) {
                return true
        } else return false
    }

    updateList(props: Props) {
        const { selectId, shownElems, showOptions } = props;
        const ulEl: any =  document.getElementById(selectId);
        const liEl: any = document.getElementById(`${selectId}-${shownElems-1}`)
        if(liEl) {
            const increaseElems = () => {
                const liRect = liEl?.getBoundingClientRect();
                const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
                if(!(liRect.bottom < 0 || liRect.top - viewHeight >= 0)) {
                    ulEl.removeEventListener('wheel', increaseElems)
                    ulEl.removeEventListener('mousemove', increaseElems)
                    showOptions(shownElems+100)
                } 
            }
            ulEl.addEventListener('wheel', increaseElems)
            ulEl.addEventListener('mousemove', increaseElems)
        }
    }

    renderItems() {
        const {
            options,
            selected,
            focusIndex,
            onClick,
            disabled,
            selectId,
            singleSelect
        } = this.props;

        return options.map((o, i) =>
            <li
                id={`${selectId}-${i}`}
                style={styles.listItem}
                key={o.hasOwnProperty("key") ? o.key : i}
            >
                <SelectItem
                    focused={focusIndex === i}
                    option={o}
                    onSelectionChanged={c => this.handleSelectionChanged(o, c)}
                    checked={singleSelect ? selected?.value === o.value : selected.includes(o.value)}
                    onClick={() => onClick(i)}
                    disabled={o.disabled || disabled}
                    singleSelect={singleSelect}
                />
            </li>
        );
    }

    render() {
        return <ul
            className="select-list"
            style={styles.list}
            id={this.props.selectId}
        >
            {this.renderItems()}
        </ul>;
    }
}

const styles: Record<string, React.CSSProperties> = {
    list: {
        margin: 0,
        paddingLeft: 0,
    },
    listItem: {
        listStyle: 'none',
    },
};

export default SelectList;
