import React from 'react';
import PropTypes from 'prop-types';
import {
    faSquare,
    faCheckSquare,
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const checkboxContainerStyle = { margin: 20 };
const CheckBox = ({ onChange, text, value, className, disabled, readOnly, labelClass }) => {
    const checkedIcon = readOnly ? faCheck : faCheckSquare;
    const unCheckedIcon = faSquare;

    return (
        <div
            style={checkboxContainerStyle}
            className={`flex checkbox-container filter-item margin-t-auto ${className}`}
        >
            <div className="checkbox-item flex margin-b-0">
                <label className="vertical-center mb-2">
                    <FontAwesomeIcon
                        icon={value ? checkedIcon : unCheckedIcon}
                        onClick={(e) => {
                            disabled || readOnly
                                ? e.preventDefault()
                                : onChange(!value);
                        }}
                        type="checkbox"
                        className="mr-3"
                    />
                    <p
                        className={labelClass || "color-light-grey"}
                        onClick={(e) => {
                            disabled || readOnly
                                ? e.preventDefault()
                                : onChange(!value);
                        }}
                    >
                        {text}
                    </p>
                </label>
            </div>
        </div>
    );
};

CheckBox.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    text: PropTypes.string,
    value: PropTypes.bool,
};

CheckBox.defaultProps = {
    className: '',
    onChange(value) {
        return value;
    },
    text: '',
    value: false,
};

export default CheckBox;
