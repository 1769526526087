// @flow
/**
 * This component represents the entire panel which gets dropped down when the
 * user selects the component.  It encapsulates the search filter, the
 * Select-all item, and the list of options.
 */
import {filterOptions} from './match-utils';
import React, {Component} from 'react';

import SelectItem from './select-item';
import SelectList from './select-list';
import getString from "./get-string";

import type {Option} from './select-item';
import uniqueId from '../../utils/uniqueId';
import { isEmpty } from 'lodash';

type Props = {
    options: Array<Option>,
    selected: Array<any>,
    selectAllLabel?: string,
    onSelectedChanged: (selected: any) => void,
    disabled?: boolean,
    disableSearch?: boolean,
    hasSelectAll: boolean,
    overrideStrings?: any,
    singleSelect?: boolean,
    toggleExpanded: (value?: boolean) => void
};

type State = {
    searchHasFocus: boolean,
    searchText: string,
    focusIndex: number,
    shownElems: number,
    selectId: string
};

class SelectPanel extends Component<Props, State> {
    searchInput: any;
    state = {
        searchHasFocus: false,
        searchText: "",
        focusIndex: 0,
        shownElems: 0,
        selectId: ''
    }

    componentDidMount(): void {
        this.showOptions(100)
        if(this.props.singleSelect) {
            this.searchInput.focus();
            this.handleSearchFocus(true);
        } 
    }

    selectAll = () => {
        const {onSelectedChanged, options} = this.props;
        const allValues = options.filter(o => !o.disabled).map(o => o.value);

        onSelectedChanged(allValues);
    }

    selectNone = () => {
        const {onSelectedChanged} = this.props;

        onSelectedChanged([]);
    }

    selectAllChanged = (checked: boolean) => {
        if (checked) {
            this.selectAll();
        } else {
            this.selectNone();
        }
    }

    handleSearchChange = (e: {target: {value: any}}) => {
        const { options } = this.props
        this.setState({
            searchText: e.target.value,
            focusIndex: -1,
            shownElems: Math.min(100, options.length)
        });
    }

    handleItemClicked = (index: number) => {
        this.setState({focusIndex: index}, () => {
            if(this.props.singleSelect) this.props.toggleExpanded(false);
        });
    }

    clearSearch = () => {
        this.setState({searchText: ""});
    }

    handleKeyDown = (e: any) => {
        switch (e.which) {
            case 38: // Up Arrow
                if (e.altKey) {
                    return;
                }

                this.updateFocus(-1);
                break;
            case 40: // Down Arrow
                if (e.altKey) {
                    return;
                }

                this.updateFocus(1);
                break;
            default:
                return;
        }

        e.stopPropagation();
        e.preventDefault();
    }

    handleSearchFocus = (searchHasFocus: boolean) => {
        this.setState({
            searchHasFocus,
            focusIndex: -1,
        });
    }

    allAreSelected() {
        const {options, selected} = this.props;
        return options.filter(o => !o.disabled).length === selected.length;
    }

    filteredOptions() {
        const {searchText, shownElems} = this.state;
        const {options} = this.props;
        const filteredOptions = filterOptions(options, searchText);
     
        return filteredOptions.slice(0, shownElems)
    }

    showOptions(increasedElems: number) {
        const { selectId, shownElems } = this.state;
        const { options } = this.props;
        const increasedCount = Math.min(increasedElems, options.length)
        const updatedState: any = {}
        if(increasedCount !== shownElems) updatedState.shownElems = increasedCount;
        if(!selectId) updatedState.selectId = uniqueId();
        if(!isEmpty(updatedState)) this.setState(updatedState)
    }

    updateFocus(offset: number) {
        const {focusIndex} = this.state;
        const {options} = this.props;
        
        let newFocus = focusIndex + offset;
        newFocus = Math.max(0, newFocus);
        newFocus = Math.min(newFocus, options.length);

        this.setState({focusIndex: newFocus});
    }

    render() {
        const {focusIndex, searchHasFocus, shownElems, selectId} = this.state;
        const {
            selectAllLabel,
            disabled,
            disableSearch,
            hasSelectAll,
            overrideStrings,
            singleSelect
        } = this.props;

        const selectAllOption = {
            label: selectAllLabel || getString("selectAll", overrideStrings),
            value: "",
        };

        const focusedSearchStyle = searchHasFocus
            ? styles.searchFocused
            : undefined;

        return <div
            className="select-panel"
            style={styles.panel}
            role="listbox"
            onKeyDown={this.handleKeyDown}
        >
            {!disableSearch && <div style={styles.searchContainer}>
                <input
                    placeholder={getString("search", overrideStrings)}
                    type="text"
                    ref={(input) => this.searchInput = input} 
                    onChange={this.handleSearchChange}
                    style={{...styles.search, ...focusedSearchStyle}}
                    onFocus={() => this.handleSearchFocus(true)}
                    onBlur={() => this.handleSearchFocus(false)}
                />
            </div>}

            {hasSelectAll && !singleSelect &&
              <SelectItem
                focused={focusIndex === 0}
                checked={this.allAreSelected()}
                option={selectAllOption}
                onSelectionChanged={this.selectAllChanged}
                onClick={() => this.handleItemClicked(0)}
                disabled={disabled}
              />
            }

            {selectId ? <SelectList
                {...this.props}
                options={this.filteredOptions()}
                focusIndex={focusIndex - 1}
                onClick={(index) => this.handleItemClicked(index + 1)}
                disabled={disabled}
                selectId={selectId}
                shownElems={shownElems}
                showOptions={(increasedElems) => this.showOptions(increasedElems)}
                singleSelect={singleSelect}
            /> : null}
        </div>;
    }
}

const styles: Record<string, React.CSSProperties> = {
    panel: {
        boxSizing : 'border-box',
    },
    search: {
        display: "block",

        maxWidth: "100%",
        borderRadius: "3px",

        boxSizing : 'border-box',
        height: '30px',
        lineHeight: '24px',
        border: '1px solid',
        borderColor: '#dee2e4',
        padding: '10px',
        width: "100%",
        outline: "none",
    },
    searchFocused: {
        borderColor: "#78c008",
    },
    searchContainer: {
        width: "100%",
        boxSizing : 'border-box',
        padding: "0.5em",
    },
};

export default SelectPanel;
