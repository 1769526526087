// @flow
/**
 * This component represents an individual item in the multi-select drop-down
 */
import React, {Component} from 'react';

export type Option = {
    value?: any,
    label?: string,
    key?: string,
    disabled?: boolean
};

type DefaultItemRendererProps = {
    checked: boolean,
    option: Option,
    disabled?: boolean,
    onClick: () => void,
    singleSelect?: boolean
};

class DefaultItemRenderer extends Component<DefaultItemRendererProps> {
    shouldComponentUpdate(nextProps: Readonly<DefaultItemRendererProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        if(JSON.stringify(nextProps.option || {}) !== JSON.stringify(this.props.option|| {}) ||
            nextProps.disabled !== this.props.disabled || 
            nextProps.checked !== this.props.checked) {
                return true
        } else return false
    }
    render() {
        const {checked, option, onClick, disabled, singleSelect } = this.props;

        const style = {
            ...styles.label,
            ...(disabled ? styles.labelDisabled : undefined),
        };

        return <span
            className="item-renderer"
        >
            {!singleSelect ? <input
                type="checkbox"
                onChange={onClick}
                checked={checked}
                tabIndex={-1}
                disabled={disabled}
            /> : null}
            <span style={style} onClick={() => {
                if(singleSelect) onClick();
            }}>
                {option.label}
            </span>
        </span>;
    }
}

type SelectItemProps = {
    option: Option,
    checked: boolean,
    focused?: boolean,
    disabled?: boolean,
    onSelectionChanged: (checked: boolean) => void,
    onClick: () => void,
    singleSelect?: boolean
};
type SelectItemState = {
    hovered: boolean
};

class SelectItem extends Component<SelectItemProps, SelectItemState> {
    static defaultProps = {}

    state = {
        hovered: false,
    }

    componentDidMount() {
        this.updateFocus();
    }

    componentDidUpdate() {
        this.updateFocus();
    }

    itemRef?: any

    toggleChecked = () => {
        const {checked, onSelectionChanged} = this.props;
        onSelectionChanged(!checked);
    }

    handleClick = () => {
        this.toggleChecked();
        this.props.onClick();
    }

    updateFocus() {
        const {focused} = this.props;

        if (focused && this.itemRef) {
            this.itemRef.focus();
        }
    }

    handleKeyDown = (e: any) => {
        switch (e.which) {
            case 13: // Enter
            case 32: // Space
                this.toggleChecked();
                break;
            default:
                return;
        }

        e.preventDefault();
    }

    shouldComponentUpdate(nextProps: Readonly<SelectItemProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        if(JSON.stringify(nextProps.option || {}) !== JSON.stringify(this.props.option|| {}) ||
            nextProps.disabled !== this.props.disabled || 
            nextProps.checked !== this.props.checked || 
            nextProps.focused !== this.props.focused) {
                return true
        } else return false
    }

    render() {
        const { option, checked, focused, disabled, singleSelect } = this.props;
        const {hovered} = this.state;

        const focusStyle = (focused || hovered)
            ? styles.itemContainerHover
            : undefined;

        return <label
            className="select-item"
            role="option"
            aria-selected={checked}
            tabIndex={-1}
            style={{...styles.itemContainer, ...focusStyle}}
            ref={ref => this.itemRef = ref}
            onKeyDown={this.handleKeyDown}
            onMouseOver={() => this.setState({hovered: true})}
            onMouseOut={() => this.setState({hovered: false})}
        >
            <DefaultItemRenderer
                singleSelect={singleSelect}
                option={option}
                checked={checked}
                onClick={this.handleClick}
                disabled={disabled}
            />
        </label>;
    }
}


const styles: Record<string, React.CSSProperties> = {
    itemContainer: {
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        color: '#666666',
        cursor: 'pointer',
        display: 'block',
        padding: '8px 10px',
    },
    itemContainerHover: {
        backgroundColor: '#ebf5ff',
        outline: 0,
    },
    label: {
        display: 'inline-block',
        verticalAlign: 'middle',
        borderBottomRightRadius: '2px',
        borderTopRightRadius: '2px',
        cursor: 'default',
        padding: '2px 5px',
    },
    labelDisabled: {
        opacity: 0.5,
    },
};

export default SelectItem;
