import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Multi from './multi-select';
import { Label } from 'reactstrap';

const SingleSelect = ({
    options,
    onChange,
    selected,
    className,
    dataName = '',
    title,
    statePropName,
    id,
    disabled,
    errorMessage,
    errorClassName,
    readOnly,
    ...props
}) => {
    return (
        <div
            data-test={dataName}
            className={`select-item-transparent ${className}`}
        >
            <Label className="title-h3-bold" for="account">
                {title}
            </Label>
            {readOnly ? (
                <div>{selected?.label || '-'}</div>
            ) : (
                <>
                    <Select
                        id={id}
                        className={`__select data-test-${dataName}`}
                        classNamePrefix="select"
                        placeholder="Choose"
                        closeMenuOnSelect
                        value={selected}
                        onChange={(obj) => onChange(obj, statePropName)}
                        options={options}
                        isDisabled={disabled}
                        {...props}
                    />
                    {errorMessage && (
                        <p className={`msg-error mt-2 ${errorClassName}`}>
                            {errorMessage}
                        </p>
                    )}
                </>
            )}
        </div>
    );
};

const MultiSelect = (props) => {
    const {
        title,
        options,
        value,
        onChange,
        className,
        dataName = '',
        disabled,
        readOnly,
        singleSelect,
        errorComponent = null,
        placeholder = 'Choose'
    } = props;
    const [readOnlyLabel, setLabel] = useState('-')
    useEffect(() => {
        if(readOnly) {
            const labels = options
            .filter(option => singleSelect ? value?.value === option.value : value?.includes(option.value))
            .map(option => option.label);
            if(labels.length) setLabel(labels.join('\n '))
        }
        
    }, [options])
    return (
        <div data-test={dataName} className={className}>
            <Label className="title-h3-bold mb-3">{title}</Label>
            {readOnly ? (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {readOnlyLabel}
                </div>
            ) : (
                <Multi
                    overrideStrings={{
                        selectSomeItems: placeholder,
                        allItemsAreSelected: 'All',
                        selectAll: 'All',
                    }}
                    options={options}
                    selected={value}
                    onSelectedChanged={(v) => onChange(v)}
                    disabled={disabled}
                    singleSelect={singleSelect}
                    {...props}
                />
            )}
            {errorComponent}
        </div>
    );
}

export { SingleSelect, MultiSelect };
