import React, { lazy } from 'react';
import OperationsDashboard from '../views/Dashboard/OperationsDashboard';
import DefaultLayout from '../views/DefaultLayout/DefaultLayout';

import { history } from './history';
// # Dashboards
const DefaultDash = lazy(() => import('../views/Dashboards/DefaultDash/DefaultDash'));
const DefaultDashboard = lazy(() => import('../views/Dashboards/DefaultDashboard/DefaultDashboard'));

// # Top Level Routes
const Login = lazy(() => import('../views/TopLevelRoutes/Login/Login'));
const Page404 = lazy(() => import('../views/TopLevelRoutes/Page404/Page404'));
const Page500 = lazy(() => import('../views/TopLevelRoutes/Page500/Page500'));
const ConcurrentSession = lazy(() => import('../views/TopLevelRoutes/ConcurrentSession/ConcurrentSession'));
const OnfidoIdentification = lazy(() => import('../views/TopLevelRoutes/OnfidoIdentification/OnfidoIdentification'));

// # Reports
const AccountStatement = lazy(() => import('../views/Pages/AccountStatement/AccountStatement'));
const TransactionHistory = lazy(() => import('../views/Pages/TransactionHistory/TransactionHistory'));
const TieredPricingCalculator = lazy(() => import('../views/Pages/TieredPricingCalculator/TieredPricingCalculator'));

// # Compliance
const RBCheck = lazy(() => import('../views/Pages/RBCheck/RBCheck'));
const ComplianceRFI = lazy(() => import('../views/Pages/ComplianceRFI/Compliance-rfi'));
const ComplianceScan = lazy(() => import('../views/Pages/ComplianceScan/Compliance-scan'));
const RBCheckViewSelected = lazy(() => import('../views/Pages/RBCheck/RBCheckViewSelected'));
const RegulatoryBlacklist = lazy(() => import('../views/Pages/RegulatoryBlacklist/RegulatoryBlacklist'));


const ComplianceAlert = lazy(() => import('../views/Pages/ComplianceAlert/ComplianceAlert'));
const PaymentAlert = lazy(() => import('../views/Pages/PaymentAlerts/PaymentAlert'));


const OnfidoPage = lazy(() => import('../views/Pages/Onfido/OnfidoPage'));
const OnfidoPageSummary = lazy(() => import('../views/Pages/Onfido/components/OnfidoPageSummary'));
const ComplianceManagement = lazy(() => import('../views/Pages/ComplianceManagement/ComplianceManagement'));
const ComplianceManagementEdit = lazy(() => import('../views/Pages/ComplianceManagement/ComplianceManagementEdit'));

// # Settings
const UserProfile = lazy(() => import('../views/Pages/UserProfile/UserProfile'));
const BankDetails = lazy(() => import('../views/Pages/BankDetails/BankDetails'));
const PasswordChange = lazy(() => import('../views/Pages/PasswordChange/PasswordChange'));
const RoleSettings = lazy(() => import('../views/Pages/RolesSettings/RoleSettings'));

// # Operations
const Stats = lazy(() => import('../views/Pages/Stats/Stats'));
const GLEntry = lazy(() => import('../views/Pages/GLEntry/GLEntry'));
const BatchGLEntrySaver = lazy(() => import('../views/Pages/BatchGLEntry/BatchGLEntry'));
const ScheduledFX = lazy(() => import('../views/Pages/ScheduledFX/ScheduledFX'));
const ManualPayout = lazy(() => import('../views/Pages/ManualPayout/ManualPayout'));
const MalaysiaReports = lazy(() => import('../views/Pages/MalaysiaReports/MalaysiaReports'));
const ReProcessPayments = lazy(() => import('../views/Pages/ReProcessPayments/ReProcessPayments'));
const LockedTransactions = lazy(() => import('../views/Pages/LockedTransactions/LockedTransactions'));
const FinanceSummaryReport = lazy(() => import('../views/Pages/FinanceSummaryReport/FinanceSummaryReport'));
const ManualPayoutConfirmation = lazy(() => import('../views/Pages/ManualPayoutConfirmation/ManualPayoutConfirmation'));
const IccCreate = lazy(() => import('../views/Pages/ICC/IccCreate'));
const InwardCreditConfirmation = lazy(() => import('../views/Pages/InwardCreditConfirmation/InwardCreditConfirmation'));

// # Settings
const UserSettings = lazy(() => import('../views/Pages/UserSettings/UserSettings'));

// #Analytics
const ConversionFunnel = lazy(() => import('../views/Pages/ConversionFunnel/ConversionFunnel'));
const ComplianceReport = lazy(() => import('../views/Pages/ComplianceReport/ComplianceReport'));
const ComplianceInProcess = lazy(() => import('../views/PayinCompliance/ComplianceTransaction/InProcess/ComplianceInProcess'));

const ComplianceHistory = lazy(() => import('../views/PayinCompliance/ComplianceTransaction/History/ComplianceHistory').then(module => ({ default: module.ComplianceHistory })));
const TrackPaymentRequestHistory = lazy(() => import('../views/PayinCompliance/reports/TrackPaymentRequestHistory'));
const FundWithdrawHistory = lazy(() => import('../views/PayinCompliance/reports/FundWithdrawHistory'));
const VirtualReceivingAccount = lazy(() => import('../views/PayinCompliance/reports/VirtualReceivingAccount'));
const VirtualReceivingAccountStatement = lazy(() => import('../views/PayinCompliance/reports/VirtualReceivingAccountStatement'));
const AccountSettings = lazy(() => import('../views/Pages/AccountSettings/AccountSettings'));
const CurrencySettings = lazy(() => import('../views/Pages/CurrencySettings/CurrencySettings'));
const ClientSettings = lazy(() => import('../views/Pages/ClientSettings/ClientSettings'));
const CallbackConfigSettings = lazy(() => import('../views/Pages/ClientSettings/components/CallbackConfig'));
const ReceivePaymentReconciliation = lazy(() => import('../views/PayinCompliance/ComplianceTransaction/Reconciliation/finops/ReceivePaymentReconciliaton').then(module => ({ default: module.ReceivePaymentReconciliation })));
const ManualTransactionUpdate = lazy(() => import('../views/Pages/Transaction/ManualTransactionUpdate'));
const RejectedICC = lazy(() => import('../views/Pages/RejectedTransactions/RejectedIcc'));
const gmoSettings  = lazy(()=>import('../views/Pages/GMO/gmoSettings'))
export const loggedInRoutes = [
    // # Analytics
    {
        path: '/r/analytics/conversionFunnel',
        name: 'Conversion Funnel',
        Component: ConversionFunnel,
    },
    {
        path: '/r/analytics/complianceReport',
        name: 'Compliance Report',
        Component: ComplianceReport,
    },

    // # Dashboards
    {
        path: '/r/dashboard/client',
        name: 'Client dashboard',
        Component: OperationsDashboard,
    },
    {
        path: '/r/dashboard/finance',
        name: 'Finance dashboard',
        Component: OperationsDashboard,
    },
    {
        path: '/r/defaultDashboard',
        name: 'Default dashboard',
        Component: DefaultDashboard,
    },
    {
        path: '/r/dashboard/compliance',
        name: 'Compliance dashboard',
        Component: OperationsDashboard,
    },
    {
        path: '/r/dashboard/operations',
        name: 'Operations dashboard',
        Component: OperationsDashboard,
    },
    {
        path: '/r/dashboard/systemadmin',
        name: 'System admin dashboard',
        Component: OperationsDashboard,
    },

    // # Reports
    {
        path: '/r/reports/tieredPricingCalculator',
        name: '',
        Component: TieredPricingCalculator,
    },

    // # Compliance
    {
        path: '/r/compliance/compliancerfi',
        name: 'Compliance RFI',
        Component: ComplianceRFI,
    },
    {
        path: '/r/compliance/complianceScan',
        name: 'Compliance Scan Report',
        Component: ComplianceScan,
    },
    {
        path: '/r/compliance/regulatoryBlacklist',
        name: 'Regulatory Blacklist',
        Component: RegulatoryBlacklist,
    },
    {
        path: '/r/compliance/beneficiarycheck',
        exact: true,
        name: 'Remitter Beneficiary check',
        Component: RBCheck,
    },
    {
        path: '/r/compliance/compliancealert',
        exact: true,
        name: 'Remitter Beneficiary check',
        Component: ComplianceAlert,
    },
    {
        path: '/r/paymentalerts',
        exact: true,
        name: 'Payment Alerts',
        Component: PaymentAlert,
    },
    {
        path: '/r/compliance/beneficiarycheck/:id',
        name: 'Remitter Beneficiary check',
        Component: RBCheckViewSelected,
    },
    {
        path: '/r/compliance/onfido',
        name: 'Onfido Page',
        Component: OnfidoPage,
    },
    {
        path: '/r/compliance/onfidoSummary',
        name: 'Onfido Page Account Summary',
        exact: true,
        Component: OnfidoPageSummary,
    },
    {
        path: '/r/compliance/complianceManagement',
        name: 'Compliance Management',
        exact: true,
        Component: ComplianceManagement,
    },
    {
        path: '/r/compliance/rejectedIcc',
        name: 'VFA Rejected ICC',
        Component: RejectedICC,
    },
    {
        path: '/r/compliance/complianceManagement/:id',
        name: 'Compliance Management',
        exact: true,
        Component: ComplianceManagementEdit,
    },

    // # Settings
    {
        path: '/r/settings/user/myProfile',
        name: 'My Profile',
        Component: UserProfile,
    },
    {
        path: '/r/settings/user/bankDetails',
        name: 'Bank Details',
        Component: BankDetails,
    },
    {
        path: '/r/settings/user/changePassword',
        name: 'Change User Password',
        Component: PasswordChange,
    },
    {
        path: '/r/settings/role',
        name: 'Roles Settings',
        Component: RoleSettings,
    },
    {
        path: '/r/settings/account',
        name: 'Account Settings',
        Component: AccountSettings,
    },
    {
        path: '/r/settings/user',
        name: 'User Settings',
        exact: true,
        Component: UserSettings,
    },
    {
        path: '/r/settings/currency',
        name: 'Currency Settings',
        Component: CurrencySettings,
    },
    {
        path: '/r/settings/client/callbackConfig/:clientId',
        name: 'Callback Config Settings',
        Component: CallbackConfigSettings,
        exact: true,
    },
    {
        path: '/r/settings/client',
        name: 'Client Settings',
        Component: ClientSettings,
    },

    // # Operations
    {
        path: '/r/operations/accountstatement',
        name: 'Account Statement',
        Component: AccountStatement,
    },
    {
        path: '/r/operations/transactionHistory',
        name: 'Transaction History',
        Component: TransactionHistory,
    },
    { path: '/r/operations/stats', name: '', Component: Stats },
    { path: '/r/operations/glentry', name: 'GL Entry', Component: GLEntry },
    { path: '/r/operations/batchglentry', name: 'Batch GL Entry', Component: BatchGLEntrySaver },
    {
        path: '/r/operations/scheduled-fx',
        name: 'Scheduled FX',
        Component: ScheduledFX,
    },
    {
        path: '/r/operations/manualpayout',
        name: 'Manual Payout',
        Component: ManualPayout,
    },
    {
        path: '/r/operations/malaysiaReports',
        name: 'Malaysia reports',
        Component: MalaysiaReports,
    },
    {
        path: '/r/operations/reprocesspayments',
        name: 'Re process payments',
        Component: ReProcessPayments,
    },
    {
        path: '/r/operations/lockedTransactions',
        name: 'Locked transactions',
        Component: LockedTransactions,
    },
    {
        path: '/r/operations/financeSummary',
        name: 'Finance Summary Report',
        Component: FinanceSummaryReport,
    },
    {
        path: '/r/operations/manualpayouttransactions',
        name: 'Manual Payout Confirmation',
        Component: ManualPayoutConfirmation,
    },
    {
        path: '/r/operations/receivePaymentReconciliation',
        name: 'Receive Payment Reconciliation',
        Component: ReceivePaymentReconciliation,
    },
    {
        path: '/r/operations/payinIcc',
        name: 'Payin ICC Simulation',
        Component: IccCreate,
    },
    {
        path: '/r/operations/inwardCreditConfirmation',
        name: 'Inward Credit Confirmation',
        Component: InwardCreditConfirmation,
    },


    // # Payin
    {
        path: '/r/compliance/transactionInProcess',
        name: 'Payin Transaction In Process',
        Component: ComplianceInProcess,
    },
    {
        path: '/r/compliance/transactionHistory',
        name: 'Payin Transaction History',
        Component: ComplianceHistory,
    },
    {
        path: '/r/compliance/trackPaymentRequestHistory/:clientId',
        name: 'Payin Track Payment Request History',
        Component: TrackPaymentRequestHistory,
    },
    {
        path: '/r/compliance/fundWithdrawHistory/:clientId',
        name: 'Payin Fund Withdraw History',
        Component: FundWithdrawHistory,
    },
    {
        path: '/r/compliance/virtualAccounts/:clientId',
        name: 'Payin Fund Withdraw History',
        Component: VirtualReceivingAccount,
    },
    {
        path: '/r/compliance/virtualReceivingAccountStatement/:clientId/:vraId',
        name: 'Virtual Receiving Account Statement',
        Component: VirtualReceivingAccountStatement,
    },
    {
        path: '/r/transaction/statusUpdate',
        name: 'Manual Transaction Update',
        Component: ManualTransactionUpdate,
    },
    {
        path: '/r/GMO',
        name: 'GMO',
        Component: gmoSettings,
    },
    // # The rest
    { path: '/r/', name: '', Component: DefaultDash },
];

export const topLevelRoutes = [
    { path: '/login', name: 'Login Page', Component: Login },
    {
        path: '/resetPassword/:id',
        name: 'Reset password page',
        Component: Login,
    },
    { path: '/404', name: 'Page 404', Component: Page404, exact: true },
    { path: '/500', name: 'Page 500', Component: Page500, exact: true },
    {
        path: '/identification',
        name: 'Verification',
        Component: OnfidoIdentification,
    },
    {
        path: '/concurrentlogin',
        name: 'Concurrent Login',
        Component: ConcurrentSession,
    },
    {
        path: '/',
        name: 'Home',
        render: (props) => {
            if (localStorage.getItem('isLoggedIn') === 'true') {
                return <DefaultLayout {...props} />;
            } else {
                history.push('/login');
            }
        },
    },
];
