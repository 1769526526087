import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppHeader, AppSidebar } from '@coreui/react';
import { sidebarUpdate } from '../../actions/sidebar-actions';
import AppSidebarNav from './DefaultSidebar';
import { DefaultFooter } from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import { RootContainer } from './RootContainer';
import { renderMenu } from './renderMenu';
import * as menus from './MenuConstants';
const DefaultLayout = (props) => {
    const dispatch = useDispatch();
    const sidebar = useSelector((state) => state.sidebar);
    const showSidebar = useSelector((state) => state.sidebar.showSidebar);
    const generated_password_changed = useSelector((state) => state.userInfo.generated_password_changed);
    const isClientChanging = useSelector((state) => state.header.isClientChanging);

    const {
        location,
        history,
    } = props;

    if (sidebar?.data?.menu?.length > 0) {
        const menuUrlsTransformed = JSON.parse(JSON.stringify(menus.urls).toLowerCase());
        const currentUrl = window.location.pathname.toLowerCase();
        /** to find the matching url which has id at the end **/
        const matchingUrlKey = Object.keys(menuUrlsTransformed).find(urlKey => currentUrl.includes(urlKey));
        const url_menus = menuUrlsTransformed[matchingUrlKey];
        if (url_menus === "na") {
            history.push('/r/dashboard/client');
        }
        else if (url_menus?.length > 0) {
            const sideMenuStringified = JSON.stringify(sidebar.data.menu).toLowerCase();
            const isMenuExists = url_menus?.some((menu) => sideMenuStringified.includes(menu)) ?? false;
            if (!isMenuExists && window.location.pathname !== '/r/dashboard/client') {
                history.push('/r/dashboard/client');
            }
        }
    }

    const hideSidebar = () =>  dispatch(sidebarUpdate(false))

    return (
        <div className="app">
            <AppHeader fixed>
                <DefaultHeader />
            </AppHeader>
            <div className={`app-body show-sidebar-${showSidebar}`}>
                <AppSidebar
                    fixed
                    display="lg"
                    onMouseLeave={() => hideSidebar()}
                >
                    {sidebar.data && (
                        <AppSidebarNav
                            // TODO: clean this component
                            navConfig={renderMenu(sidebar.data.menu)}
                            location={location}
                        // {...this.props}
                        />
                    )}
                </AppSidebar>
                <RootContainer
                    generated_password_changed={generated_password_changed}
                    isClientChanging={isClientChanging}
                    hideSidebar={hideSidebar}
                />
            </div>
            <DefaultFooter />
        </div>
    );
};

export default DefaultLayout;
