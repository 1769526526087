import { GET_GMO_DATA_SUCCESS } from '../../actions/types';

const initialState = {
  data: []
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
      case GET_GMO_DATA_SUCCESS: {
          return {
              ...state,
              data: action.payload.data,
              success: action.payload.success
          };
      }
      default:
          return state;
  }
}
