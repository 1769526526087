import { createSlice } from '@reduxjs/toolkit';
import {
    COMMON_CONSTANTS,
    COMMON_COMMENTS,
    MENU_ITEMS,
    LEGAL_ENTITIES,
    CLIENT_LIST_ALL,
    COUNTRIES,
    TIMEZONES,
    USER_ROLES,
    TIME_OFFSETS,
    USER_LOGOUT,
    DEFAULT_NON_BPSP_PRICING_REQUEST,
    DEFAULT_NON_BPSP_PRICING_SUCCESS,
    DEFAULT_NON_BPSP_PRICING_FAILURE,
    GENERAL_CONFIG,
    CLIENT_LIST_WITH_RISK_LEVEL,
    GENERAL_CONFIGURATION
} from '../actions/types';

const initialState = {
    clientListAll: [],
    commonConstants: {},
    commonComments: {},
    countries: {},
    timezones: [],
    userRoles: [],
    timeOffsets: [],
    legalEntities: {},
    menuItems: {},
    defaultNonBPSPPricing: {},
    defaultNonBPSPPricingLoading: false,
    clientListWithRiskLevel: [],
    generalConfig: {}
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case COMMON_CONSTANTS:
            return { ...state, commonConstants: action.payload };
        case COMMON_COMMENTS:
            return { ...state, commonComments: action.payload };
        case MENU_ITEMS:
            return { ...state, menuItems: action.payload };
        case LEGAL_ENTITIES:
            return { ...state, legalEntities: action.payload.data };
        case CLIENT_LIST_ALL:
            return { ...state, clientListAll: action.payload };
        case COUNTRIES:
            return { ...state, countries: action.payload };
        case TIMEZONES:
            return { ...state, timezones: action.payload };
        case USER_ROLES:
            return { ...state, userRoles: action.payload };
        case TIME_OFFSETS:
            return { ...state, timeOffsets: action.payload };
        case USER_LOGOUT:
            return { ...state, clientListAll: [] };
        case DEFAULT_NON_BPSP_PRICING_REQUEST:
            return { ...state, defaultNonBPSPPricingLoading: true };
        case DEFAULT_NON_BPSP_PRICING_SUCCESS:
            return {
                ...state,
                defaultNonBPSPPricingLoading: false,
                defaultNonBPSPPricing: action.payload,
            };
        case DEFAULT_NON_BPSP_PRICING_FAILURE:
            return { ...state, defaultNonBPSPPricingLoading: false };
        case GENERAL_CONFIG:
            return { ...state, generalConfig: action.payload };
        case CLIENT_LIST_WITH_RISK_LEVEL:
            return { ...state, clientListWithRiskLevel: action.payload };
        case GENERAL_CONFIGURATION:
            return { ...state, generalConfiguration: action.payload };
      
        default:
            return state;
    }
}
export const slice = createSlice({
    name: 'constants',
    initialState: {
        fetching: false,
    },
    reducers: {
        fetchingCommonConstants(state, action) {
            state.fetching = true;
        },
    },
});

export const { reducer, actions, name } = slice;
export const { fetchingCommonConstants } = actions;
