import {
    COMPLIANCE_RFI_DATA_REQUEST,
    COMPLIANCE_RFI_DATA_SUCCESS,
    COMPLIANCE_RFI_DATA_FAILURE,
    CHANGE_PAGE_SIZE_REQUEST,
    COMPLIANCE_RFI_GET_RFIDOCSDETAILS_REQUEST,
    COMPLIANCE_RFI_GET_RFIDOCSDETAILS_SUCCESS,
    COMPLIANCE_RFI_APPROVED_BY_LIST,
    COMPLIANCE_RFI_CHECKBOX,
    COMPLIANCE_RFI_APPROVE_TRANSACTION,
    COMPLIANCE_RFI_APPROVE_TRANSACTION_SUCCESS,
    COMPLIANCE_RFI_REJECT_TRANSACTION,
    COMPLIANCE_RFI_REJECT_TRANSACTION_SUCCESS,
    COMPLIANCE_RFI_REQUEST_DETAILS,
    COMPLIANCE_RFI_REQUEST_DETAILS_SUCCESS,
    COMPLIANCE_RFI_RESPONSE_APPROVAL_REQUEST,
    COMPLIANCE_RFI_RESPONSE_APPROVAL_SUCCESS,
    COMPLIANCE_RFI_RESPONSE_APPROVAL_FAILURE,
    COMPLIANCE_RFI_RESPONSE_SUBMIT_REQUEST, 
    COMPLIANCE_RFI_RESPONSE_SUBMIT_SUCCESS, 
    COMPLIANCE_RFI_RESPONSE_SUBMIT_ERROR,
    COMPLIANCE_RFI_DOC_SUBMIT_REQUEST,
    COMPLIANCE_RFI_DOC_SUBMIT_SUCCESS,
    DOWNLOAD_COMPLIANCE_RFI_DOC_SUBMIT_REQUEST,
    DOWNLOAD_COMPLIANCE_RFI_DOC_SUBMIT_SUCCESS
} from '../../actions/types';

const initialState = {
    clientListAll: [],
    pagination: { items: [] },
    loading: false,
    isWaitingApprovalOrRejection: false,
    approved: '',
    pendingItems: [],
    approvedItems: [],
    failedItems: [],
    loadingDataTable: '',
    reqCurrentInfo: [],
    rfiCheckbox: '',
    rfiApprovedByList: [],
    error: null,
    loadingRfiResponse: false,
    rfiResponseError: '', 
    rfiResponseSucess: false,
};
export default function (state = initialState, action = {}) {
    switch (action.type) {
        case COMPLIANCE_RFI_DATA_REQUEST:
            return { ...state, loading: true, error: null };
        case COMPLIANCE_RFI_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: {
                    items: action.payload.data.data,
                    total: action.payload.data.recordsFiltered,
                },
            };
        case COMPLIANCE_RFI_DATA_FAILURE:
            return { ...state, loading: false };
        case CHANGE_PAGE_SIZE_REQUEST:
            return { ...state, loading: true };
        case COMPLIANCE_RFI_GET_RFIDOCSDETAILS_REQUEST:
            return { ...state, loadingDataTable: true, error: null };
        case COMPLIANCE_RFI_GET_RFIDOCSDETAILS_SUCCESS:
            return {
                ...state,
                loadingDataTable: false,
                reqCurrentInfo: action.payload,
            };
        case COMPLIANCE_RFI_APPROVED_BY_LIST:
            return { ...state, rfiApprovedByList: action.payload };
        case COMPLIANCE_RFI_CHECKBOX:
            return { ...state, rfiCheckbox: action.payload };
        case COMPLIANCE_RFI_APPROVE_TRANSACTION:
            return { ...state, isWaitingApprovalOrRejection: true };
        case COMPLIANCE_RFI_APPROVE_TRANSACTION_SUCCESS:
            return { ...state, isWaitingApprovalOrRejection: false };
        case COMPLIANCE_RFI_REJECT_TRANSACTION:
            return { ...state, isWaitingApprovalOrRejection: true };
        case COMPLIANCE_RFI_REJECT_TRANSACTION_SUCCESS:
            return { ...state, isWaitingApprovalOrRejection: false };
        case COMPLIANCE_RFI_REQUEST_DETAILS:
            return { ...state, isWaitingApprovalOrRejection: true };
        case COMPLIANCE_RFI_REQUEST_DETAILS_SUCCESS:
            return { ...state, isWaitingApprovalOrRejection: false };
        case COMPLIANCE_RFI_RESPONSE_APPROVAL_REQUEST:
            return { ...state, pendingItems: [action.payload], error: null };
        case COMPLIANCE_RFI_RESPONSE_APPROVAL_SUCCESS:
            return {
                ...state,
                approvedItems: [...state.approvedItems, action.payload],
            };
        case COMPLIANCE_RFI_RESPONSE_APPROVAL_FAILURE:
            return {
                ...state,
                failedItems: [...state.failedItems, action.payload],
            };
        case COMPLIANCE_RFI_RESPONSE_SUBMIT_REQUEST:
            return { ...state, loadingRfiResponse: true, rfiResponseError: '' };
        case COMPLIANCE_RFI_RESPONSE_SUBMIT_SUCCESS:
            return { ...state, loadingRfiResponse: false, rfiResponseSucess: action.payload.success };
        case COMPLIANCE_RFI_RESPONSE_SUBMIT_ERROR:
            return { ...state, loadingRfiResponse: false, rfiResponseError: action.payload.message };
        case COMPLIANCE_RFI_DOC_SUBMIT_REQUEST:
        case COMPLIANCE_RFI_DOC_SUBMIT_SUCCESS:
        case DOWNLOAD_COMPLIANCE_RFI_DOC_SUBMIT_REQUEST:
        case DOWNLOAD_COMPLIANCE_RFI_DOC_SUBMIT_SUCCESS:
            return { ...state };
        default:
            return state;
    }
}
