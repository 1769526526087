// @flow
/**
 * This component is designed to be a multi-selct component which supports
 * the selection of several items in a picklist.  It was meant to mimic the
 * style of react-select but the multi-select behavior didn't work for our
 * our needs.
 *
 * Arguments:
 * - options: The {value, label}[] options to be displayed
 * - values: The currently selected values []
 * - onSelectedChanged: An event to notify the caller of new values
 */
import React, {CSSProperties, Component} from 'react';

import Dropdown from './dropdown';
import SelectPanel from './select-panel';
import getString from './get-string';
import SelectItem from './select-item';

import type {
    Option,
} from './select-item';

type Props = {
    options: Array<Option>,
    selected: any,
    onSelectedChanged?: (selected: any) => void,
    selectAllLabel?: string,
    disabled?: boolean,
    disableSearch?: boolean,
    shouldToggleOnHover: boolean,
    hasSelectAll: boolean,
    overrideStrings?: any,
    labelledBy: string
    singleSelect?: boolean,
    clearable?: boolean
};

class MultiSelect extends Component<Props> {
    static defaultProps = {
        hasSelectAll: true,
        shouldToggleOnHover: false,
    }

    getSelectedText() {
        const {options, selected, singleSelect} = this.props;
        if(!singleSelect) {
            const selectedOptions = selected
            .map((s: any) => options.find(o => o.value === s));

            const selectedLabels = selectedOptions.map((s: any) => s ? s.label : "");

            return selectedLabels.join(", ");
        } else return selected?.label
        
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<{}>, nextContext: any): boolean {
        if(JSON.stringify(nextProps.options || {}) !== JSON.stringify(this.props.options || {}) || 
            JSON.stringify(nextProps.selected || {}) !== JSON.stringify(this.props.selected|| {}) ||
            nextProps.disabled !== this.props.disabled) {
                return true
        } else return false
    }


    renderHeader() {
        const {
            options,
            selected,
            overrideStrings,
        } = this.props;

        if (selected?.length === 0 || !selected) {
            return <span style={styles.noneSelected}>
                {getString("selectSomeItems", overrideStrings)}
            </span>;
        }

        return <span>
            {selected?.length === options.length
                ? getString("allItemsAreSelected", overrideStrings)
                : this.getSelectedText()
            }
        </span>;
    }

    handleSelectedChanged = (selected: any) => {
        const {onSelectedChanged, disabled} = this.props;

        if (disabled) {
            return;
        }

        if (onSelectedChanged) {
            onSelectedChanged(selected);
        }
    }

    render() {
        const {
            options,
            selected,
            selectAllLabel,
            disabled,
            disableSearch,
            shouldToggleOnHover,
            hasSelectAll,
            overrideStrings,
            labelledBy,
            singleSelect,
            clearable
        } = this.props;

        return <div className="multi-select">
            <Dropdown
                contentComponent={SelectPanel}
                shouldToggleOnHover={shouldToggleOnHover}
                contentProps={{
                    options,
                    selected,
                    hasSelectAll,
                    selectAllLabel,
                    onSelectedChanged: this.handleSelectedChanged,
                    disabled,
                    disableSearch,
                    overrideStrings,
                    singleSelect,
                    clearable
                }}
                labelledBy={labelledBy}
            >
                {this.renderHeader()}
            </Dropdown>
        </div>;
    }
}

const styles: Record<string, CSSProperties> = {
    noneSelected: {
        color: "#aaa",
    },
};

export default MultiSelect;
export {Dropdown, SelectPanel, SelectItem};